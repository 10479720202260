exports.components = {
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-aktivitaeten-js": () => import("./../../../src/pages/aktivitaeten.js" /* webpackChunkName: "component---src-pages-aktivitaeten-js" */),
  "component---src-pages-beirat-js": () => import("./../../../src/pages/beirat.js" /* webpackChunkName: "component---src-pages-beirat-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newslettersuccess-js": () => import("./../../../src/pages/newslettersuccess.js" /* webpackChunkName: "component---src-pages-newslettersuccess-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-themen-js": () => import("./../../../src/pages/themen.js" /* webpackChunkName: "component---src-pages-themen-js" */),
  "component---src-pages-transferunit-js": () => import("./../../../src/pages/transferunit.js" /* webpackChunkName: "component---src-pages-transferunit-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-pages-wp-admin-js": () => import("./../../../src/pages/wp-admin.js" /* webpackChunkName: "component---src-pages-wp-admin-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-thema-post-js": () => import("./../../../src/templates/thema-post.js" /* webpackChunkName: "component---src-templates-thema-post-js" */)
}

